@use "libs/shared/styles/app-theme" as *;
@use "libs/search-and-help-shared-lib/styles/shared-styles" as *;
@use "libs/shared/styles/style-guide-typography" as *;
@use "libs/shared/styles/style-guide-colors" as *;
@import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

:root {
    --ion-background-color: #{$color-gray-50};
    --ion-font-family: #{$typography-font-family};
    --ion-text-color: #{$color-gray-700};
}

// NOTE: Adds scroll to body in storybook
body.sb-show-main.sb-main-padded {
    overflow: auto;
    position: relative;
}

.ion-page {
    overflow-y: auto;
    background-color: $color-gray-50;
}

ion-content {
    --overflow: hidden;
}

.task-popup {
    .leaflet-popup-content-wrapper {
        border-radius: 4px;
        background-color: $color-white;
        color: $color-gray-700;
        box-shadow: 0 3px 5px 0 rgba($color-gray-900, 0.2), 0 1px 18px 0 rgba($color-gray-900, 0.12),
            -1px 0px 10px 0px rgba($color-gray-900, 0.08);

        .leaflet-popup-content {
            margin: 0;

            p {
                margin: 0;
            }
        }
    }

    .leaflet-popup-tip {
        display: none;
    }
}

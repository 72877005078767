@use "sass:map";
@use "libs/shared/styles/style-guide-colors" as *;

$_desktop-wide-breakpoint: 1440px;
$_desktop-breakpoint: 1025px;
$_tablet-breakpoint: 768px;
$_smartphone-wide-breakpoint: 431px;
$_breakpoint-buffer: 0.02;

$viewport-sizes: (
    "desktop-wide": "(min-width: #{$_desktop-wide-breakpoint})",
    "desktop": "(min-width: #{$_desktop-breakpoint}) and (max-width: #{$_desktop-wide-breakpoint - $_breakpoint-buffer})",
    "tablet": "(min-width: #{$_tablet-breakpoint}) and (max-width: #{$_desktop-breakpoint - $_breakpoint-buffer})",
    "smartphone-wide": "(min-width: #{$_smartphone-wide-breakpoint}) and (max-width: #{$_tablet-breakpoint - $_breakpoint-buffer})",
    "smartphone": "(max-width: #{$_smartphone-wide-breakpoint - $_breakpoint-buffer})",
);

@mixin for-viewport-size($size-names...) {
    $query: "";

    @for $i from 0 to length($size-names) {
        $size-name: nth($size-names, $i + 1);

        @if not map.has-key($viewport-sizes, $size-name) {
            @error "#{$size-name} is not a valid viewport size. Expected one of #{map.keys($viewport-sizes)}.";
        }

        $size-query: map.get($viewport-sizes, $size-name);

        @if $query != "" {
            $query: "#{$query},";
        }
        $query: "#{$query} screen and #{$size-query}";
    }

    @if $query != "" {
        @media #{$query} {
            @content;
        }
    }
}

@mixin toast-box-shadow {
    box-shadow: -1px 0 10px rgba($color-gray-900, 0.08), 0 1px 18px rgba($color-gray-900, 0.12), 0px 3px 5px rgba($color-gray-900, 0.2);
}

@mixin default-box-shadow($alpha: 0.14) {
    box-shadow: 0 6px 12px rgba($color-gray-400, $alpha);
}

@mixin stroke($size: 1px, $color: #000) {
    text-shadow: -#{$size} -#{$size} 0 $color, 0 -#{$size} 0 $color, #{$size} -#{$size} 0 $color, #{$size} 0 0 $color,
        #{$size} #{$size} 0 $color, 0 #{$size} 0 $color, -#{$size} #{$size} 0 $color, -#{$size} 0 0 $color;
}

@mixin focus-outline($outlineOffset: -2px) {
    outline: 2px solid $color-secondary-700;
    outline-offset: $outlineOffset;
}

@mixin scrollbar-colors(
    $thumb-color: $color-gray-100,
    $hover-color: $color-gray-200,
    $active-color: $color-gray-300,
    $track-color: transparent,
    $is-root: false
) {
    @supports (not selector(::-webkit-scrollbar)) {
        // NOTE: global scrollbars require :root for FF
        @if $is-root {
            :root {
                scrollbar-width: thin;
                scrollbar-color: $thumb-color $track-color;
            }
        } @else {
            scrollbar-width: thin;
            scrollbar-color: $thumb-color $track-color;
        }
    }

    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;

        &-track {
            background: $track-color;
        }

        &-thumb {
            background: $thumb-color;
            border-radius: 32px;

            &:hover {
                background: $hover-color;
            }

            &:active {
                background: $active-color;
            }
        }
    }
}

@font-face {
    font-family: "IconFont";
    src: url('IconFont.woff2?t=1720528520183') format('woff2'),
        url('IconFont.woff?t=1720528520183') format('woff'),
        url('IconFont.ttf?t=1720528520183') format('truetype');
}

.iconfont {
    font-family: "IconFont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-add:before { content: "\e900"; }
.icon-align-center:before { content: "\e901"; }
.icon-align-justify:before { content: "\e902"; }
.icon-align-left:before { content: "\e903"; }
.icon-align-right:before { content: "\e904"; }
.icon-arrow-left-fill:before { content: "\e905"; }
.icon-arrow-down:before { content: "\e906"; }
.icon-arrow-go:before { content: "\e907"; }
.icon-arrow-left:before { content: "\e908"; }
.icon-arrow-right:before { content: "\e909"; }
.icon-arrow-up:before { content: "\e90a"; }
.icon-article:before { content: "\e90b"; }
.icon-attachment:before { content: "\e90c"; }
.icon-bold:before { content: "\e90d"; }
.icon-building:before { content: "\e90e"; }
.icon-calendar-event:before { content: "\e90f"; }
.icon-shield-star:before { content: "\e910"; }
.icon-shield:before { content: "\e911"; }
.icon-shield-cross:before { content: "\e912"; }
.icon-check:before { content: "\e913"; }
.icon-checkbox-blank:before { content: "\e914"; }
.icon-checkbox:before { content: "\e915"; }
.icon-child:before { content: "\e916"; }
.icon-close:before { content: "\e917"; }
.icon-file-copy:before { content: "\e918"; }
.icon-file-list:before { content: "\e919"; }
.icon-download:before { content: "\e91a"; }
.icon-drag-move:before { content: "\e91b"; }
.icon-drawer-close:before { content: "\e91c"; }
.icon-drawer-open:before { content: "\e91d"; }
.icon-drone:before { content: "\e91e"; }
.icon-earth:before { content: "\e91f"; }
.icon-edit:before { content: "\e920"; }
.icon-error-warning-fill:before { content: "\e921"; }
.icon-eye:before { content: "\e922"; }
.icon-eye-off:before { content: "\e923"; }
.icon-file-edit:before { content: "\e924"; }
.icon-file-forbid:before { content: "\e925"; }
.icon-file-info:before { content: "\e926"; }
.icon-file-mark:before { content: "\e927"; }
.icon-file-unknow:before { content: "\e928"; }
.icon-file-user:before { content: "\e929"; }
.icon-file-shield:before { content: "\e92a"; }
.icon-filter:before { content: "\e92b"; }
.icon-flight-land:before { content: "\e92c"; }
.icon-flight-takeoff:before { content: "\e92d"; }
.icon-gamepad:before { content: "\e92e"; }
.icon-arrow-go-back:before { content: "\e92f"; }
.icon-arrow-go-forward:before { content: "\e930"; }
.icon-information-fill:before { content: "\e931"; }
.icon-information:before { content: "\e932"; }
.icon-italic:before { content: "\e933"; }
.icon-list-ordered:before { content: "\e934"; }
.icon-list-unordered:before { content: "\e935"; }
.icon-loader:before { content: "\e936"; }
.icon-mail:before { content: "\e937"; }
.icon-treasure-map:before { content: "\e938"; }
.icon-map-pin:before { content: "\e939"; }
.icon-menu-fold:before { content: "\e93a"; }
.icon-menu-unfold:before { content: "\e93b"; }
.icon-more:before { content: "\e93c"; }
.icon-sticky-note:before { content: "\e93d"; }
.icon-parent:before { content: "\e93e"; }
.icon-pause-circle:before { content: "\e93f"; }
.icon-ball-pen:before { content: "\e940"; }
.icon-phone:before { content: "\e941"; }
.icon-play-fill:before { content: "\e942"; }
.icon-profile:before { content: "\e943"; }
.icon-question:before { content: "\e944"; }
.icon-radio-button:before { content: "\e945"; }
.icon-radio-button-blank:before { content: "\e946"; }
.icon-circle:before { content: "\e947"; }
.icon-refresh:before { content: "\e948"; }
.icon-close-circle-fill:before { content: "\e949"; }
.icon-reply:before { content: "\e94a"; }
.icon-road-map:before { content: "\e94b"; }
.icon-route:before { content: "\e94c"; }
.icon-save:before { content: "\e94d"; }
.icon-scissors:before { content: "\e94e"; }
.icon-search:before { content: "\e94f"; }
.icon-settings:before { content: "\e950"; }
.icon-shape-cylinder:before { content: "\e951"; }
.icon-radius:before { content: "\e952"; }
.icon-line-down:before { content: "\e953"; }
.icon-arrow-down-3:before { content: "\e954"; }
.icon-arrow-up-3:before { content: "\e955"; }
.icon-line-up:before { content: "\e956"; }
.icon-shape-line:before { content: "\e957"; }
.icon-shape-prism:before { content: "\e958"; }
.icon-focus:before { content: "\e959"; }
.icon-checkbox-circle:before { content: "\e95a"; }
.icon-markup:before { content: "\e95b"; }
.icon-rest-time:before { content: "\e95c"; }
.icon-error-warning:before { content: "\e95d"; }
.icon-close-circle:before { content: "\e95e"; }
.icon-strikethrough:before { content: "\e95f"; }
.icon-subtract:before { content: "\e960"; }
.icon-focus-2:before { content: "\e961"; }
.icon-task:before { content: "\e962"; }
.icon-time:before { content: "\e963"; }
.icon-delete-bin:before { content: "\e964"; }
.icon-underline:before { content: "\e965"; }
.icon-user-follow:before { content: "\e966"; }
.icon-user:before { content: "\e967"; }
.icon-add-circle:before { content: "\e968"; }
.icon-camera:before { content: "\e969"; }
.icon-group:before { content: "\e96a"; }
.icon-community:before { content: "\e96b"; }
.icon-tracker:before { content: "\e96c"; }
.icon-menu:before { content: "\e96d"; }
.icon-notification:before { content: "\e96e"; }
.icon-mail-lock:before { content: "\e96f"; }
.icon-sign-prohibition:before { content: "\e970"; }
.icon-mail-unread:before { content: "\e971"; }
.icon-mail-open:before { content: "\e972"; }
.icon-mail-close:before { content: "\e973"; }
.icon-lock:before { content: "\e974"; }
.icon-user-settings:before { content: "\e975"; }
.icon-play-circle:before { content: "\e976"; }
.icon-file:before { content: "\e977"; }
.icon-file-paper:before { content: "\e978"; }
.icon-user-add:before { content: "\e979"; }
.icon-checkbox-indeterminate:before { content: "\e97a"; }
.icon-logout-circle:before { content: "\e97b"; }
.icon-shield-check:before { content: "\e97c"; }
.icon-dashboard:before { content: "\e97d"; }
.icon-draft:before { content: "\e97e"; }
.icon-base-station:before { content: "\e97f"; }
.icon-home-2:before { content: "\e980"; }
.icon-home:before { content: "\e981"; }
.icon-archive:before { content: "\e982"; }
.icon-plane:before { content: "\e983"; }
.icon-checkbox-circle-fill:before { content: "\e984"; }
.icon-tree:before { content: "\e985"; }
.icon-map-pin-time:before { content: "\e986"; }
.icon-star:before { content: "\e987"; }
.icon-alarm-warning:before { content: "\e988"; }
.icon-forbid:before { content: "\e989"; }
.icon-pen-nib:before { content: "\e98a"; }
.icon-pin-off:before { content: "\e98b"; }
.icon-file-text:before { content: "\e98c"; }
.icon-lock-unlock:before { content: "\e98d"; }
.icon-arrow-left-right:before { content: "\e98e"; }
.icon-pen-nib-land:before { content: "\e98f"; }
.icon-pen-nib-takeoff:before { content: "\e990"; }
.icon-pen-nib-oval:before { content: "\e991"; }
.icon-pen-nib-prism:before { content: "\e992"; }
.icon-pen-nib-line:before { content: "\e993"; }
.icon-pin-distance:before { content: "\e994"; }
.icon-side-bar:before { content: "\e995"; }
.icon-dashboard-2:before { content: "\e996"; }
.icon-arrow-left-right-2:before { content: "\e997"; }
.icon-arrow-right-2:before { content: "\e998"; }
.icon-arrow-up-2:before { content: "\e999"; }
.icon-arrow-down-2:before { content: "\e99a"; }
.icon-arrow-up-down-2:before { content: "\e99b"; }
.icon-arrow-up-down-left:before { content: "\e99c"; }
.icon-dot-down:before { content: "\e99d"; }
.icon-dot-middle:before { content: "\e99e"; }
.icon-lines:before { content: "\e99f"; }
.icon-lines-right:before { content: "\e9a0"; }
.icon-hand:before { content: "\e9a1"; }
.icon-chat:before { content: "\e9a2"; }
.icon-bookmark:before { content: "\e9a3"; }
.icon-bookmark-fill:before { content: "\e9a4"; }
.icon-dot-right-small:before { content: "\e9a5"; }
.icon-dot-left-small:before { content: "\e9a6"; }
.icon-dot-middle-small:before { content: "\e9a7"; }
.icon-shape-cylinder-3d:before { content: "\e9a8"; }
.icon-segment:before { content: "\e9a9"; }
.icon-cylinder-top:before { content: "\e9aa"; }
.icon-cylinder-down:before { content: "\e9ab"; }
.icon-prism:before { content: "\e9ac"; }
.icon-prism-top:before { content: "\e9ad"; }
.icon-prism-down:before { content: "\e9ae"; }
.icon-windy:before { content: "\e9af"; }
.icon-temp-cold:before { content: "\e9b0"; }
.icon-rainy:before { content: "\e9b1"; }
.icon-helicopter-lpr-bg:before { content: "\e9b2"; }
.icon-helicopter-lpr:before { content: "\e9b3"; }
.icon-polygon:before { content: "\e9b4"; }
.icon-stamp:before { content: "\e9b5"; }
.icon-size-thick:before { content: "\e9b6"; }
.icon-size-thin:before { content: "\e9b7"; }
.icon-cursor:before { content: "\e9b8"; }
.icon-text:before { content: "\e9b9"; }
.icon-color-middle:before { content: "\e9ba"; }
.icon-color:before { content: "\e9bb"; }
.icon-rectangle:before { content: "\e9bc"; }
.icon-color-pattern:before { content: "\e9bd"; }
.icon-drone-swarm:before { content: "\e9be"; }
.icon-pencil-ruler:before { content: "\e9bf"; }
.icon-eject:before { content: "\e9c0"; }
.icon-expanded-up-down:before { content: "\e9c1"; }
.icon-leaving-area-path:before { content: "\e9c2"; }
.icon-leaving-area-track:before { content: "\e9c3"; }
.icon-conflict-flash:before { content: "\e9c4"; }
.icon-conflict-arrows:before { content: "\e9c5"; }
.icon-base-station-fill:before { content: "\e9c6"; }
.icon-sms-phone:before { content: "\e9c7"; }
.icon-sms-tick:before { content: "\e9c8"; }
.icon-mail-envelope:before { content: "\e9c9"; }
.icon-mail-tick:before { content: "\e9ca"; }
.icon-mail-error:before { content: "\e9cb"; }
.icon-student:before { content: "\e9cc"; }
.icon-school:before { content: "\e9cd"; }
.icon-postpone:before { content: "\e9ce"; }
.icon-cylinder-dash:before { content: "\e9cf"; }
.icon-cylinder-fill-top-down:before { content: "\e9d0"; }
.icon-cylinder-fill:before { content: "\e9d1"; }
.icon-google:before { content: "\e9d2"; }
.icon-alarm-warning-fill:before { content: "\e9d3"; }
.icon-arrow-up-double:before { content: "\e9d4"; }
.icon-chart:before { content: "\e9d5"; }
.icon-star-badge:before { content: "\e9d6"; }
.icon-mail-send:before { content: "\e9d7"; }
.icon-global:before { content: "\e9d8"; }
.icon-function:before { content: "\e9d9"; }
.icon-user-star:before { content: "\e9da"; }
.icon-flashlight-filled:before { content: "\e9db"; }
.icon-flashlight-outline:before { content: "\e9dc"; }
.icon-checkbox-x:before { content: "\e9dd"; }
.icon-map-pin-fill:before { content: "\e9de"; }
.icon-edit-fill:before { content: "\e9df"; }
.icon-map-unpin-fill:before { content: "\e9e0"; }
.icon-cylinder-line:before { content: "\e9e1"; }
.icon-external-link:before { content: "\e9e2"; }
.icon-moon:before { content: "\e9e3"; }
.icon-sun:before { content: "\e9e4"; }
.icon-thumb-up:before { content: "\e9e5"; }
.icon-thumb-down:before { content: "\e9e6"; }
.icon-logout-rectangle:before { content: "\e9e7"; }
.icon-send-plane-fill:before { content: "\e9e8"; }
.icon-scales-2:before { content: "\e9e9"; }
.icon-sun-cloudy:before { content: "\e9ea"; }
.icon-speed-up-fill:before { content: "\e9eb"; }
.icon-elevation:before { content: "\e9ec"; }
.icon-user-help:before { content: "\e9ed"; }
.icon-helicopter:before { content: "\e9ee"; }
.icon-car:before { content: "\e9ef"; }
.icon-team:before { content: "\e9f0"; }
.icon-nurse:before { content: "\e9f1"; }
.icon-sailboat:before { content: "\e9f2"; }
.icon-drop:before { content: "\e9f3"; }
.icon-bubble-chart:before { content: "\e9f4"; }
.icon-camera-switch-line:before { content: "\e9f5"; }
.icon-zoom-in-line:before { content: "\e9f6"; }
.icon-map:before { content: "\e9f7"; }
.icon-finish:before { content: "\e9f8"; }
.icon-shapes:before { content: "\e9f9"; }
.icon-science:before { content: "\e9fa"; }
.icon-todo:before { content: "\e9fb"; }
.icon-tools:before { content: "\e9fc"; }
.icon-contract:before { content: "\e9fd"; }
.icon-wifi-off:before { content: "\e9fe"; }
.icon-signal-tower-fill:before { content: "\e9ff"; }
.icon-hems-emr:before { content: "\ea00"; }
.icon-tree-fill:before { content: "\ea01"; }
.icon-plane-airport-fill:before { content: "\ea02"; }
.icon-helicopter-lpr-fill:before { content: "\ea03"; }
.icon-barricade-fill:before { content: "\ea04"; }
.icon-stack-fill:before { content: "\ea05"; }
.icon-text-block-complex-1b:before { content: "\ea06"; }
.icon-text-block-complex-1a:before { content: "\ea07"; }
.icon-text-block-complex-2b:before { content: "\ea08"; }
.icon-team-fill-complex-1b:before { content: "\ea09"; }
.icon-team-fill:before { content: "\ea0a"; }
.icon-text-block-complex-2a:before { content: "\ea0b"; }
.icon-team-fill-complex-1a:before { content: "\ea0c"; }
.icon-global-poland:before { content: "\ea0d"; }
.icon-arrow-up-single:before { content: "\ea0e"; }
.icon-arrow-up-triple:before { content: "\ea0f"; }
.icon-hems:before { content: "\ea10"; }
.icon-lifebuoy:before { content: "\ea11"; }
.icon-file-details:before { content: "\ea12"; }
.icon-alert-fill:before { content: "\ea13"; }
.icon-alert:before { content: "\ea14"; }
.icon-flag:before { content: "\ea15"; }
.icon-map-unpin:before { content: "\ea16"; }
.icon-id-card:before { content: "\ea17"; }
.icon-flag-fill:before { content: "\ea18"; }
.icon-cylinder-question:before { content: "\ea19"; }
.icon-map-info:before { content: "\ea1a"; }
.icon-coins:before { content: "\ea1b"; }
.icon-magic:before { content: "\ea1c"; }
.icon-bank-card:before { content: "\ea1d"; }
.icon-pencil:before { content: "\ea1e"; }
.icon-booklet:before { content: "\ea1f"; }
.icon-draw-arrow-straight:before { content: "\ea20"; }
.icon-swap-box:before { content: "\ea21"; }
.icon-stack-line:before { content: "\ea22"; }
.icon-fire:before { content: "\ea23"; }
.icon-star-fill:before { content: "\ea24"; }
.icon-upload:before { content: "\ea25"; }
.icon-markpen:before { content: "\ea26"; }
.icon-drag-drop:before { content: "\ea27"; }
.icon-share:before { content: "\ea28"; }
.icon-diploma:before { content: "\ea29"; }
.icon-mail-notification-1:before { content: "\ea2a"; }
.icon-mail-notification-2:before { content: "\ea2b"; }
.icon-exchange-funds-line:before { content: "\ea2c"; }
.icon-map-pin-accepted:before { content: "\ea2d"; }
.icon-equalizer-fill:before { content: "\ea2e"; }
.icon-user-follow-fill:before { content: "\ea2f"; }
.icon-mail-send-fill:before { content: "\ea30"; }
.icon-archive-fill:before { content: "\ea31"; }
.icon-calendar-event-fill:before { content: "\ea32"; }
.icon-waypoint:before { content: "\ea33"; }
.icon-airstrip:before { content: "\ea34"; }
.icon-flyeye:before { content: "\ea35"; }
.icon-status-online-offline:before { content: "\ea36"; }
.icon-window-fill:before { content: "\ea37"; }
.icon-calendar-check-fill:before { content: "\ea38"; }
.icon-calendar-close-fill:before { content: "\ea39"; }
.icon-pause-circle-fill:before { content: "\ea3a"; }
.icon-notification-2-fill:before { content: "\ea3b"; }
.icon-arrow-up-circle-fill:before { content: "\ea3c"; }
.icon-arrow-down-circle-fill:before { content: "\ea3d"; }
.icon-first-aid-kit-fill:before { content: "\ea3e"; }
.icon-treasure-map-fill:before { content: "\ea3f"; }
.icon-hourglass-fill:before { content: "\ea40"; }
.icon-window-check-fill:before { content: "\ea41"; }
.icon-drone-hidden:before { content: "\ea42"; }
.icon-north:before { content: "\ea43"; }
.icon-focus-2-fill:before { content: "\ea44"; }
.icon-timer-flash-fill:before { content: "\ea45"; }


/**
 * Make by Icon Font Exporter
 * https://www.figma.com/community/plugin/1129455674275940478/Icon-Font-Exporter
 */

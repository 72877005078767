@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/style-guide-typography" as *;
@use "libs/shared/styles/mixins" as *;

.mat-menu-panel[role="menu"] {
    @include toast-box-shadow;
    background: $color-white;
    border-radius: 6px;
    min-height: auto;

    .mat-menu-content:not(:empty) {
        padding: 8px 0;
    }

    .mat-menu-item {
        @include body-small-short;
        height: auto;
        padding: 10px 16px;
        display: flex;
        gap: 8px;
        align-items: center;
        color: $color-typography-light-body;

        &:disabled {
            opacity: 0.5;
        }

        &:hover:not(:disabled) {
            background-color: $color-secondary-50;
        }

        &:focus-visible {
            @include focus-outline;
            border-radius: 4px;
        }

        dtm-ui-icon {
            color: $color-gray-500;
        }
    }
}

.mat-menu-panel.wide-panel {
    width: auto;
    max-width: inherit;
}

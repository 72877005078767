@use "libs/shared/styles/style-guide-colors" as *;

.sah-card {
    padding: 16px 24px;
    border-radius: 8px;
    background: $color-white;
    box-shadow: 0 8px 16px rgba($color-gray-500, 0.12);
}

.task-status {
    &-active {
        background: rgba($color-status-success, 0.2);
        border: 1px solid $color-status-success;
    }

    &-completed {
        background: rgba($color-gray-500, 0.2);
        border: 1px solid $color-gray-200;
    }

    &-incomplete {
        background: $color-white;
        border: 1px solid $color-gray-100;
    }

    &-paused {
        background: rgba($color-primary-600, 0.2);
        border: 1px solid $color-primary-600;
    }

    &-pending-acceptance {
        background: rgba($color-secondary-400, 0.2);
        border: 1px solid $color-secondary-400;
    }

    &-planned {
        background: rgba($color-gray-500, 0.1);
        border: 1px dashed $color-gray-500;
    }

    &-rejected {
        background: rgba($color-gray-500, 0.2);
        border: 1px dashed $color-gray-200;
    }
}

@use "./libs/shared/styles/components/buttons" as *;
@use "./libs/shared/styles/style-guide-colors" as *;
@use "./libs/shared/styles/style-guide-typography" as *;
@use "./libs/shared/styles/mixins" as *;
@use "leaflet-marker-cluster";

:root {
    --map-height: 100%;
    --map-border-radius: 0;

    --info-popup-bg-color: #{rgba($color-gray-900, 0.8)};
    --info-popup-color: #{$color-light-contrast};
    --info-popup-tip-bg-color: #{$color-gray-900};
}

.leaflet-container {
    font-family: $typography-font-family;
}

.map {
    height: var(--map-height);
    border-radius: var(--map-border-radius);
}

.info-popup {
    & > .leaflet-popup-content-wrapper {
        background-color: var(--info-popup-bg-color);
        color: var(--info-popup-color);
    }

    .leaflet-popup-tip {
        background-color: var(--info-popup-tip-bg-color);
    }
}

.checkin-popup {
    & > .leaflet-popup-content-wrapper {
        width: 289px;
        border-radius: 6px;
        opacity: 90%;
        background-color: $color-white;
        display: flex;
        box-shadow: 0 4px 6px rgba($color-gray-500, 0.32);

        .leaflet-popup-content {
            margin: 16px 24px;
        }
    }

    a.leaflet-popup-close-button {
        color: $color-secondary-700;
        top: 16px;
        right: 24px;
        font-size: 24px;
    }

    .leaflet-popup-tip {
        background-color: $color-white;
        opacity: 90%;
    }
}

.point-position {
    cursor: crosshair;
}

.leaflet-bottom.leaflet-right {
    width: 100%;
}

.leaflet-button {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $color-white;
    color: $color-dark-contrast;
    -webkit-tap-highlight-color: rgba($color-gray-200, 0.5);

    height: 44px;
    width: 44px;
    border-radius: 5px;

    & > dtm-ui-icon {
        font-size: 1.7rem;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }

    &:hover:not([disabled]) {
        background-color: $color-gray-50;
    }

    &:focus-visible {
        outline-color: $color-dark-contrast;
    }
}

.controls-section {
    pointer-events: none;
    display: flex;
    flex-direction: column;

    padding: 0 10px;

    &.leaflet-control {
        margin: 0;
    }

    div {
        pointer-events: auto;
        margin: 10px 0 0;

        &:last-of-type {
            margin-bottom: 10px;
        }

        button {
            margin: 0;
        }
    }

    .zoom-controls button {
        &:first-of-type {
            border-radius: 5px 5px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 5px 5px;
        }
    }
}

.controls-bottom-right {
    width: 100%;
    align-items: end;
}

@media screen and (max-height: 400px) {
    .default-controls {
        display: flex;

        & > div {
            display: flex;
        }

        .leaflet-button {
            margin: 0 10px;
        }

        .zoom-controls {
            & .leaflet-button {
                margin: 0;

                &:first-of-type {
                    border-radius: 5px 0 0 5px;
                }

                &:last-of-type {
                    border-radius: 0 5px 5px 0;
                }
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.layer-smooth-transition {
    transition: opacity 0.7s ease-in;

    // !important is required to override inline styling for leaflet layer
    &.hidden {
        opacity: 0 !important;
    }
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    @include default-box-shadow;
}

.leaflet-attribution-flag {
    display: none !important; // NOTE: Required to override leaflet styling
}

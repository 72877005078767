@use "sass:math";
@use "../mixins" as *;

html {
    --grid-max-grid-width: 1124px;
}

.grid {
    --content-padding-width: var(--content-margin-width);
    display: grid;
    width: 100%;
    grid-template-rows: auto;
    grid-auto-flow: row;
    gap: var(--grid-gutter-width);
    margin-left: auto;
    margin-right: auto;
    max-width: var(--grid-max-grid-width, 100%);
    transition: width 300ms;
    padding-bottom: var(--grid-padding-bottom, var(--content-padding-width));
}

@mixin _grid-template($columns-amount, $gutter-width, $margin-width, $prefix: "") {
    html {
        --grid-gutter-width: #{$gutter-width};
        --grid-margin-width: #{$margin-width};
    }

    .grid {
        grid-template-columns: repeat($columns-amount, 1fr);

        > * {
            grid-column: span #{$columns-amount};
        }
    }

    @if $prefix != "" {
        $prefix: "-" + $prefix;
    }

    @include _grid-columns($columns-amount, $prefix);
}

@mixin _grid-columns($columns-amount, $prefix) {
    @for $i from 1 through $columns-amount {
        .grid {
            > .grid-col#{$prefix}-0 {
                display: none;
            }

            > .grid-col#{$prefix}-#{$i} {
                grid-column: span #{$i};
            }

            @for $j from 1 through $columns-amount {
                $column-start-line: math.floor(($columns-amount - $j) / 2) + 1;

                &.grid-maxsize#{$prefix}-#{$j} {
                    > * {
                        grid-column: #{$column-start-line} / span #{$j};
                    }

                    > .grid-col#{$prefix}-#{$i}:first-child {
                        grid-column: #{$column-start-line} / span #{$i};
                    }
                }
            }
        }
    }
}

// smartphone mode
@include for-viewport-size(smartphone) {
    @include _grid-template(6, 16px, 16px, "smartphone");
}

// smartphone-wide mode
@include for-viewport-size(smartphone-wide) {
    @include _grid-template(12, 16px, 24px, "smartphone-wide");
}

// tablet mode
@include for-viewport-size(tablet) {
    @include _grid-template(12, 16px, 24px, "tablet");
}

// desktop mode
@include for-viewport-size(desktop) {
    @include _grid-template(12, 24px, 24px, "desktop");
}

// desktop-wide mode
@include for-viewport-size(desktop-wide) {
    @include _grid-template(12, 24px, auto, "desktop-wide");
}

// helper columns
@include for-viewport-size(smartphone) {
    @include _grid-columns(6, "-sm");
}

@include for-viewport-size(smartphone-wide, tablet) {
    @include _grid-columns(12, "-md");
}

@include for-viewport-size(desktop, desktop-wide) {
    @include _grid-columns(12, "");
}

@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/mixins" as *;

// font-family
$typography-font-family: Manrope, Arial, sans-serif;
$icon-font-family: "IconFont";

// font-size
$typography-size-body-default: 16px;
$typography-size-body-small-short: 0.875rem; // 14px
$typography-size-body-small-long: 0.875rem; // 14px
$typography-size-body-hint: 0.625rem; // 10px
$typography-size-body-description: 0.75rem; // 12px
$typography-size-heading-1: 2rem; // 32px
$typography-size-heading-2: 1.75rem; // 28px
$typography-size-heading-3: 1.5rem; // 24px
$typography-size-heading-4: 1.25rem; // 20px
$typography-size-heading-5: 1rem; // 16px
$typography-size-heading-6: 0.875rem; // 14px

// line-height
$typography-line-height-body-default: 150%;
$typography-line-height-body-small-short: 140%;
$typography-line-height-body-small-long: 160%;
$typography-line-height-body-hint: 120%;
$typography-line-height-body-description: 150%;
$typography-line-height-heading-1: 140%;
$typography-line-height-heading-2: 150%;
$typography-line-height-heading-3: 150%;
$typography-line-height-heading-4: 160%;
$typography-line-height-heading-5: 150%;
$typography-line-height-heading-6: 140%;
$typography-line-height-heading-small: 140%;

// font-weight
$typography-weight-normal: 500;
$typography-weight-bold: 700;
$typography-weight-light-bold: 600;

@mixin heading-1 {
    font-size: $typography-size-heading-1;
    line-height: $typography-line-height-heading-1;
    font-weight: $typography-weight-bold;

    @include for-viewport-size(smartphone, smartphone-wide) {
        font-size: $typography-size-heading-2;
        line-height: $typography-line-height-heading-small;
    }
}

@mixin heading-2 {
    font-size: $typography-size-heading-2;
    line-height: $typography-line-height-heading-2;
    font-weight: $typography-weight-bold;

    @include for-viewport-size(smartphone, smartphone-wide) {
        font-size: $typography-size-heading-3;
        line-height: $typography-line-height-heading-small;
    }
}

@mixin heading-3 {
    font-size: $typography-size-heading-3;
    line-height: $typography-line-height-heading-3;
    font-weight: $typography-weight-bold;

    @include for-viewport-size(smartphone, smartphone-wide) {
        font-size: $typography-size-heading-4;
        line-height: $typography-line-height-heading-small;
    }
}

@mixin heading-4 {
    font-size: $typography-size-heading-4;
    line-height: $typography-line-height-heading-4;
    font-weight: $typography-weight-bold;

    @include for-viewport-size(smartphone, smartphone-wide) {
        font-size: $typography-size-heading-5;
        line-height: $typography-line-height-heading-small;
    }
}

@mixin heading-5 {
    font-size: $typography-size-heading-5;
    line-height: $typography-line-height-heading-5;
    font-weight: $typography-weight-bold;

    @include for-viewport-size(smartphone, smartphone-wide) {
        font-size: $typography-size-heading-6;
        line-height: $typography-line-height-heading-small;
    }
}

@mixin heading-6 {
    font-size: $typography-size-heading-6;
    line-height: $typography-line-height-heading-6;
    font-weight: $typography-weight-bold;
}

@mixin body-small-short {
    font-size: $typography-size-body-small-short;
    line-height: $typography-line-height-body-small-short;
    font-weight: $typography-weight-normal;
}

@mixin body-small-long {
    font-size: $typography-size-body-small-long;
    line-height: $typography-line-height-body-small-long;
    font-weight: $typography-weight-normal;
}

@mixin body-hint {
    font-size: $typography-size-body-hint;
    line-height: $typography-line-height-body-hint;
    font-weight: $typography-weight-normal;
}

@mixin body-description {
    font-size: $typography-size-body-description;
    line-height: $typography-line-height-body-description;
    font-weight: $typography-weight-light-bold;
}

@mixin body-description-normal {
    font-size: $typography-size-body-description;
    line-height: $typography-line-height-body-description;
    font-weight: $typography-weight-normal;
}

@mixin body-16 {
    font-size: $typography-size-body-default;
    line-height: $typography-line-height-body-default;
    font-weight: $typography-weight-normal;
}
